var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      attrs: {
        color: "appbar_color",
        fixed: "",
        app: "",
        "elevate-on-scroll": "",
        elevation: "1",
        dense: "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "w-100 d-flex align-items-center my-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-content-center flex-fill",
            },
            [
              _vm.helloActive
                ? _c(
                    "span",
                    { staticClass: "f15 font-weight-bold text-info" },
                    [_vm._v(_vm._s(_vm.$t("Hello!")))]
                  )
                : _vm._e(),
              _vm.helloActive
                ? _c(
                    "span",
                    { staticClass: "f11 font-weight-bold text-info" },
                    [_vm._v(_vm._s(_vm.fullName))]
                  )
                : _vm._e(),
              _vm.backActive && _vm.$vuetify.rtl
                ? _c(
                    "v-btn",
                    {
                      attrs: { icon: "", small: "", color: "icon_color" },
                      on: { click: _vm.goBack },
                    },
                    [
                      _c("iconly", {
                        attrs: {
                          type: "light",
                          name: "arrow-right",
                          size: "2x",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.backActive && !_vm.$vuetify.rtl
                ? _c(
                    "v-btn",
                    {
                      attrs: { icon: "", small: "", color: "icon_color" },
                      on: { click: _vm.goBack },
                    },
                    [
                      _c("iconly", {
                        attrs: {
                          type: "light",
                          name: "arrow-left",
                          size: "2x",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-toolbar-title",
            { staticClass: "f15 fw-bold flex-fill text-center" },
            [
              _vm.toolbarTitleActive
                ? _c("span", [_vm._v(_vm._s(_vm.toolbarTitle))])
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-fill justify-content-end" },
            [
              _vm.notificationActive
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        small: "",
                        color: "icon_color",
                        to: "/c/notifications",
                      },
                    },
                    [
                      _c(
                        "v-badge",
                        {
                          staticClass: "lh-18",
                          attrs: {
                            left: "",
                            overlap: "",
                            bordered: "",
                            color: "red",
                            content: _vm.notificationCount,
                            value: _vm.notificationCount,
                          },
                        },
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "notification",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.threeDotsActive
                ? _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          dark: "",
                                          icon: "",
                                          small: "",
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "text-center",
                                        attrs: { color: "icon_color" },
                                      },
                                      [_vm._v("mdi-dots-vertical")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2841706390
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.threeDotsMenu, function (item, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              attrs: { link: "", to: item.to },
                              on: { click: item.click },
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t(item.title)))])]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }